<template>
  <v-col
    :cols="$vuetify.breakpoint.xl ? 4 : $vuetify.breakpoint.lg ? 5 : 12"
    class="d-flex justify-space-between justify-lg-center align-center"
    :order="$vuetify.breakpoint.mdAndUp ? 0 : 1"
  >
    <v-chip :small="$vuetify.breakpoint.xs" class="pa-4 mr-2" label :color="participantSession.color" dark>
      <font-awesome-icon icon="user" pull="left"></font-awesome-icon>
      <span class="font-weight-bold">{{ participantSession.name }}</span></v-chip
    >
    <v-chip :small="$vuetify.breakpoint.xs" class="pa-4 mr-2" label color="secondary" dark>
      <font-awesome-icon icon="star" pull="left"></font-awesome-icon>
      <span class="mr-2">Score</span>
      <span class="font-weight-bold">{{ participantSession.totalscore }}</span></v-chip
    >

    <v-chip :small="$vuetify.breakpoint.xs" class="pa-4 mr-2" label color="secondary" dark>
      <font-awesome-icon icon="bolt" pull="left"></font-awesome-icon>
      <span class="mr-2">Powerups</span><span class="font-weight-bold">{{ availablePowerUps }}</span>
    </v-chip>
  </v-col>
</template>

<script>
import { mapState } from "vuex"
export default {
  name: "ParticipantInfo",
  computed: {
    ...mapState({
      participantSession: (state) => state.game.participantSession,
      gameSession: (state) => state.game.gameSession,
    }),
    availablePowerUps() {
      if (this.gameSession.advanced) {
        return this.gameSession.maxlifelines - this.participantSession.powerupcount
      } else {
        return 0
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
