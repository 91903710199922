var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-content-click": true,
        "nudge-width": 200,
        "offset-y": "",
        top: "",
        disabled: _vm.disabled
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "text-sm-h6 subtitle1 rounded-lg",
                      attrs: {
                        color: "secondary",
                        dark: "",
                        small: _vm.$vuetify.breakpoint.xs,
                        disabled: _vm.disabled
                      },
                      on: { click: _vm.hintopened }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "info-circle", pull: "left" }
                  }),
                  _vm._v(" Hint")
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.menu,
        callback: function($$v) {
          _vm.menu = $$v
        },
        expression: "menu"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { color: "transparent" } },
        [
          _c(
            "v-list",
            { staticClass: "background rounded-xl" },
            [
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-list-item-title",
                        { staticClass: "font-weight-bold secondary--text" },
                        [_vm._v("He played for more than 20 years in the game")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function($event) {
                      _vm.menu = false
                    }
                  }
                },
                [_vm._v(" Cancel ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }