<template>
  <v-container v-if="loaded" fluid class="wrapper ma-0 pa-0">
    <template v-if="gameSession">
      <ParticipantWaitCard :username="participantSession.name" v-if="!viewquestion && participantSession.name">
        <template v-slot:timer>
          <ParticipantWaitTimer :seconds="gaptime" :standardtime="standardtime"></ParticipantWaitTimer>
        </template>
      </ParticipantWaitCard>
      <ParticipantQuestion
        v-if="viewquestion && !gameEnded"
        :gaptime="gaptime"
        :time="time"
        :route="route"
      ></ParticipantQuestion>
    </template>
    <ConfirmDialog ref="confirm"></ConfirmDialog>
  </v-container>
</template>

<script>
import { Timestamp } from "firebase/firestore"
import { mapState, mapMutations } from "vuex"
import ParticipantWaitCard from "@/components/game/participant/ParticipantWaitCard.vue"
import ParticipantQuestion from "@/components/game/participant/ParticipantQuestion.vue"
import ParticipantWaitTimer from "@/components/game/participant/ParticipantWaitTimer.vue"
import ConfirmDialog from "@/components/common/ConfirmDialog.vue"
import { getGameSession, getParticipantSession, getParticipantList, updateParticipantSession } from "@/api/GameApi"

export default {
  components: {
    ParticipantWaitCard,
    ParticipantQuestion,
    ParticipantWaitTimer,
    ConfirmDialog,
  },

  name: "Participant",

  data() {
    return {
      route: null,
      loaded: false,
      gaptime: null,
      time: null,
      notimer: false,
      gapTimerId: null,
      standardtime: 10,
      started: null,
      questionTimerId: null,
    }
  },

  computed: {
    ...mapState({
      participantSession: (state) => state.game.participantSession,
      gameSession: (state) => state.game.gameSession,
      participantList: (state) => state.game.participantList,
    }),

    isLastQuestion() {
      return this.participantSession.index + 1 === this.gameSession.questions.length
    },

    viewquestion() {
      return (
        this.gameSession.futuretime ||
        this.participantSession.futuretime ||
        (this.gameSession.type === "S" && this.gaptime === 0)
      )
    },

    gameEnded() {
      if (this.gameSession || this.participantSession) {
        return this.gameSession.end || this.participantSession.end
      } else {
        return false
      }
    },

    classicModeShowAnswer() {
      return !this.notimer && this.gameSession.timerenabled && this.gameSession.type !== "L"
    },
  },

  watch: {
    gameEnded: {
      handler: function (newval) {
        if (newval) {
          this.$router.push({ name: "ParticipantEndQuiz" })
        }
      },
    },

    "gameSession.type": {
      handler: function (newValue) {
        if (newValue === "C" || newValue === "S") {
          console.log("GAME TYPE WATCHER TRIGGERED", newValue)
          this.starttimer()
        }
      },
    },

    "participantSession.showanswer": {
      handler: function (newValue) {
        if (newValue && this.classicModeShowAnswer) {
          console.log("PARTICIPANT SHOW ANSWER WATCHER TRIGGERED")
          this.classicModeGapTimer()
        }
      },
    },

    "gameSession.futuretime": {
      handler: function (newValue) {
        if (newValue) {
          if (!this.notimer) {
            console.log("GAMESESSION FUTURE TIME WATCHER TRIGGERED")
            this.liveModeTimer()
          }
        }
      },
    },

    "participantSession.futuretime": {
      handler: function (newValue) {
        if (newValue) {
          if (!this.notimer && this.gameSession.timerenabled) {
            console.log("PARTICIPANT FUTURE TIME WATCHER TRIGGERED")
            this.classicModeQuestionTimer()
          }
        }
      },
    },
  },

  mounted() {
    this.$eventBus.$on("next-question", this.nextQuestion)
    this.$eventBus.$on("ask-confirmation", this.askConfirmationForEndingQuiz)
    this.$eventBus.$on("end-quiz", this.endquiz)
    this.started = localStorage.qiztostart
    this.route = this.$route.params.id
    this.fetchSession(this.route).then(() => {
      console.log("finished")
      this.loaded = true
      // this.starttimer()
    })
  },

  beforeDestroy() {
    this.$eventBus.$off("ask-confirmation")
    this.$eventBus.$off("end-quiz")
  },

  methods: {
    ...mapMutations({ showAlert: "SHOW_SNACKBAR" }),

    futuretime() {
      return Timestamp.now().seconds + this.standardtime
    },

    async fetchSession(r) {
      try {
        const res = r.split("-")
        const [quizid, sessionid, participantsessionid] = res
        return Promise.all([
          getGameSession(quizid, sessionid),
          getParticipantSession(quizid, sessionid, participantsessionid),
          getParticipantList(quizid, sessionid),
        ])
      } catch (err) {
        this.showAlert(err.message)
      }
    },

    async starttimer() {
      if (this.started === "notstarted") {
        console.log("INITIAL GAP TIMER STARTING")
        this.classicModeGapTimer()
      } else {
        console.log(localStorage.qiztostart)
      }
    },

    classicModeQuestionTimer() {
      console.log("CLASSIC MODE TIMER STARTING")
      this.questionTimerId = setInterval(() => {
        if (Timestamp.now().seconds <= this.participantSession.futuretime && !this.participantSession.showanswer) {
          this.time = this.participantSession.futuretime - Timestamp.now().seconds
        } else {
          this.time = 0
          clearInterval(this.questionTimerId)
        }
      }, 1000)
    },

    liveModeTimer() {
      console.log("LIVE MODE TIMER STARTING")
      this.questionTimerId = setInterval(() => {
        if (Timestamp.now().seconds <= this.gameSession.futuretime) {
          this.time = this.gameSession.futuretime - Timestamp.now().seconds
        } else {
          this.time = 0
          clearInterval(this.questionTimerId)
        }
      }, 1000)
    },

    classicModeGapTimer() {
      console.log("GAP TIMER STARTING")
      const futuretime = this.futuretime()
      this.gapTimerId = setInterval(() => {
        if (Timestamp.now().seconds <= futuretime) {
          this.gaptime = futuretime - Timestamp.now().seconds
        } else {
          if (!this.participantSession.futuretime) {
            localStorage.qiztostart = "started"
            this.started = "started"
            this.start()
          } else {
            this.nextQuestion()
          }
          clearInterval(this.gapTimerId)
        }
      }, 1000)
    },

    async start() {
      console.log("QUIZ STARTING")
      const { type, timer } = this.gameSession
      const questiontimer = this.gameSession.questions[0].timer
      const futuretime = type === "L" || type === "C" ? Timestamp.now().seconds + questiontimer || timer : 0
      try {
        await updateParticipantSession(this.route, { index: 0, futuretime: futuretime })
        this.snackmsg = "Let's Roll!"
        this.showAlert(this.snackmsg)
      } catch (err) {
        console.log(err)
        this.showAlert(err.message)
      }
    },

    async nextQuestion() {
      console.log("NEXTQUESTION")
      const index = this.participantSession.index + 1
      const questionlength = this.gameSession.questions.length
      const { timerenabled, timer } = this.gameSession
      const questiontimer = this.gameSession.questions[index]?.timer
      try {
        if (index !== questionlength) {
          const futuretime = timerenabled ? Timestamp.now().seconds + questiontimer || timer : 0
          await updateParticipantSession(this.route, {
            index: index,
            futuretime: futuretime,
            showanswer: false,
          })
        } else if (index === questionlength) {
          console.log("QUIZ NATURAL END")
          this.endquiz(true)
        }
      } catch (err) {
        console.error(err)
        this.showAlert(err.message)
      }
    },

    cleargaptimer() {
      console.log("GAPTIMER CLEAR")
      clearInterval(this.gapTimerId)
    },

    clearQuestionTimer() {
      console.log("QUESTION TIMER CLEAR")
      clearInterval(this.questionTimerId)
    },

    async askConfirmationForEndingQuiz(fromq) {
      if (
        await this.$refs.confirm.open("End Quiz", "Quiz will end, progress until now will be saved. Are you sure ?", {
          color: "red",
        })
      ) {
        console.log("ENDQUIZ")
        this.endquiz(fromq)
      }
    },

    async endquiz(fromq) {
      if (fromq) {
        console.log("ENDQUIZ - CLEARING QUESTION TIMER")
        this.clearQuestionTimer()
      }
      console.log("ENDQUIZ - CLEARING GAP TIMER")
      this.cleargaptimer()
      try {
        await updateParticipantSession(this.route, { end: true })
      } catch (err) {
        console.error(err)
        this.showAlert(`${this.$store.state.alertmsgs.unexpected}`)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.wrapper {
  background-color: #fff3e0;
  // opacity: 0.8;
  background-image: repeating-radial-gradient(circle at 0 0, transparent 0, #fff3e0 10px),
    repeating-linear-gradient(#ffdfad55, #ffdfad);
  // background-color: #fff3e0;
  // // opacity: 0.8;
  // background: radial-gradient(circle, transparent 20%, #fff3e0 20%, #fff3e0 80%, transparent 80%, transparent),
  //   radial-gradient(circle, transparent 20%, #fff3e0 20%, #fff3e0 80%, transparent 80%, transparent) 25px 25px,
  //   linear-gradient(#ffdfad 2px, transparent 2px) 0 -1px, linear-gradient(90deg, #ffdfad 2px, #fff3e0 2px) -1px 0;
  // background-size: 50px 50px, 50px 50px, 25px 25px, 25px 25px;
}
</style>
