var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "d-flex justify-center align-self-end" },
    [
      _c(
        "v-col",
        { attrs: { cols: "auto" } },
        [
          _vm.gameSession.type === "S" &&
          !_vm.participantSession.end &&
          !_vm.participantSession.showanswer
            ? _c(
                "v-btn",
                {
                  staticClass: "rounded-lg",
                  attrs: { large: "", outlined: "", color: "secondary" },
                  on: { click: _vm.skip }
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "forward", pull: "left" }
                  }),
                  _vm._v(" Skip ")
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "auto" } },
        [
          _vm.gameSession.type === "S" &&
          !_vm.participantSession.end &&
          _vm.participantSession.showanswer
            ? _c(
                "v-btn",
                {
                  staticClass: "rounded-lg",
                  attrs: { large: "", outlined: "", color: "secondary" },
                  on: { click: _vm.nextquestion }
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "arrow-circle-right", pull: "left" }
                  }),
                  _vm._v(" Next ")
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "auto" } },
        [
          _vm.gameSession.type !== "L"
            ? _c(
                "v-btn",
                {
                  staticClass: "rounded-lg",
                  attrs: { large: "", outlined: "", color: "secondary" },
                  on: { click: _vm.endquiz }
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "stop", pull: "left" }
                  }),
                  _vm._v(" End Quiz ")
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }