<template>
  <v-menu v-model="menu" :close-on-content-click="true" :nudge-width="200" offset-y top :disabled="disabled">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="secondary"
        dark
        :small="$vuetify.breakpoint.xs"
        v-bind="attrs"
        v-on="on"
        class="text-sm-h6 subtitle1 rounded-lg"
        :disabled="disabled"
        @click="hintopened"
      >
        <font-awesome-icon icon="info-circle" pull="left"></font-awesome-icon>
        Hint</v-btn
      >
    </template>

    <v-card color="transparent">
      <v-list class="background rounded-xl">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold secondary--text"
              >He played for more than 20 years in the game</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text @click="menu = false"> Cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "ParticipantHintPopover",
  props: {
    disabled: {
      default: () => false,
      type: Boolean,
    },
  },
  data: () => ({
    fav: true,
    menu: false,
    hintused: false,
  }),
  methods: {
    hintopened() {
      if (!this.hintused) {
        this.$emit("hint-used")
        this.hintused = true
      }
    },
  },
}
</script>
