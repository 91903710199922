<template>
  <v-row class="d-flex answer-section justify-center align-stretch" dense v-if="question">
    <v-col :cols="$vuetify.breakpoint.mdAndUp ? 4 : 12" class="d-flex flex-column justify-center align-center">
      <lottie-player
        v-if="this.question.answertype !== 'P' && answerRight"
        src="https://assets10.lottiefiles.com/packages/lf20_FizAqM.json"
        background="transparent"
        speed="1"
        style="width: 150px; height: 150px"
        autoplay
      ></lottie-player>
      <lottie-player
        v-if="this.question.answertype !== 'P' && !answerRight"
        src="https://assets2.lottiefiles.com/packages/lf20_slGFhN.json"
        background="transparent"
        speed="1"
        style="width: 150px; height: 150px"
        autoplay
      ></lottie-player>
      <lottie-player
        v-if="this.question.answertype === 'P'"
        src="https://assets10.lottiefiles.com/packages/lf20_X4UwkZ.json"
        background="transparent"
        speed="0.5"
        style="width: 150px; height: 150px"
        autoplay
      ></lottie-player>
      {{ answerRight }}
      <p class="subtitle1 text-center font-weight-bold secondary--text" v-if="this.question.answertype === 'P'">
        This was a poll. No right answer!
      </p>
      <p
        class="subtitle1 text-center font-weight-bold secondary--text"
        v-if="this.question.answertype !== 'P' && answerRight"
      >
        You got it right!
      </p>
      <p
        class="subtitle1 text-center font-weight-bold secondary--text"
        v-if="this.question.answertype !== 'P' && !answerRight"
      >
        Oops, you got it wrong. Correct answer is...
      </p>
      <p class="overline text-center font-weight-black secondary--text" v-if="this.question.answertype === 'P'">Poll</p>
      <p class="text-h5 text-sm-h4 text-center font-weight-black secondary--text">
        {{ correctAnswers }}
      </p>
    </v-col>
    <v-col :cols="$vuetify.breakpoint.mdAndUp ? 8 : 12" v-if="gameSession.type !== 'L'">
      <slot name="leaderboard"></slot>
    </v-col>
    <v-col :cols="$vuetify.breakpoint.mdAndUp ? 8 : 12" v-else>
      <slot name="answer-stats"></slot>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapState } from "vuex"
export default {
  name: "ParticipantRightAnswer",
  computed: {
    ...mapGetters({ question: "getCurrentQuizQuestion" }),
    ...mapState({
      gameSession: (state) => state.game.gameSession,
      participantSession: (state) => state.game.participantSession,
    }),
    correctAnswers() {
      let tempAnswers = []
      if (this.question.answertype !== "P") {
        this.question.answers
          .filter((answer, index) => this.question.correctanswers[index])
          .forEach((answer) => tempAnswers.push(answer))
      } else {
        tempAnswers.push("This was a Poll, there is NO right answer")
      }
      return tempAnswers.toString()
    },
    answerRight() {
      if (this.gameSession.type !== "L" && this.participantSession.details[this.participantSession.index]) {
        return this.correctAnswers.includes(this.participantSession.details[this.participantSession.index].chosenanswer)
      } else if (this.gameSession.type === "L" && this.participantSession.details[this.gameSession.index]) {
        return this.correctAnswers.includes(this.participantSession.details[this.gameSession.index].chosenanswer)
      } else {
        return false
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
