var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.gameSession
    ? _c(
        "v-container",
        { staticClass: "background waitcard", attrs: { "fill-height": "" } },
        [
          _c(
            "v-row",
            { attrs: { justify: "center", align: "center", "no-gutters": "" } },
            [
              _vm.gameSession.type === "L"
                ? _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("lottie-player", {
                        staticStyle: { width: "50vw", height: "50vh" },
                        attrs: {
                          src:
                            "https://assets10.lottiefiles.com/packages/lf20_2LBdNH.json",
                          background: "transparent",
                          speed: "1",
                          loop: "",
                          autoplay: ""
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-col", { attrs: { cols: "12" } }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "text-h4 text-center secondary--text font-weight-bold"
                  },
                  [_vm._v("welcome " + _vm._s(_vm.username))]
                ),
                _vm.gameSession.type === "L"
                  ? _c(
                      "p",
                      {
                        staticClass:
                          "text-h5 text-center secondary--text font-weight-medium"
                      },
                      [_vm._v(" please wait. while others are joining! ")]
                    )
                  : _vm._e(),
                _vm.gameSession.type === "C"
                  ? _c(
                      "p",
                      {
                        staticClass:
                          "text-h5 text-center secondary--text font-weight-medium"
                      },
                      [_vm._v(" Brace yourself! Next Question In.... ")]
                    )
                  : _vm._e(),
                _vm.gameSession.type === "S"
                  ? _c(
                      "p",
                      {
                        staticClass:
                          "text-h5 text-center secondary--text font-weight-medium"
                      },
                      [_vm._v(" Getting things ready...We start in.. ")]
                    )
                  : _vm._e()
              ]),
              _vm.gameSession.type === "C" || _vm.gameSession.type === "S"
                ? _c(
                    "v-col",
                    {
                      staticClass: "pt-6 d-flex justify-center align-center",
                      attrs: { col: "12" }
                    },
                    [_vm._t("timer")],
                    2
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }