var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "leaderboard justify-start", attrs: { fluid: "" } },
    [
      _c("lottie-player", {
        staticClass: "lottie",
        staticStyle: { width: "100%", height: "100%" },
        attrs: {
          src: "https://assets1.lottiefiles.com/packages/lf20_m2emnrvx.json",
          background: "transparent",
          speed: "1",
          autoplay: ""
        }
      }),
      _c("v-simple-table", {
        staticClass: "transparent",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c("thead", [
                  _c("tr", { staticClass: "rounded-lg" }, [
                    _c(
                      "th",
                      {
                        staticClass:
                          "secondary--text subtitle1 text-sm-h6 font-weight-bold"
                      },
                      [_vm._v("Rank")]
                    ),
                    _c(
                      "th",
                      {
                        staticClass:
                          "secondary--text subtitle1 text-sm-h6 font-weight-bold"
                      },
                      [_vm._v("Name")]
                    ),
                    _c(
                      "th",
                      {
                        staticClass:
                          "secondary--text subtitle1 text-sm-h6 font-weight-bold"
                      },
                      [_vm._v("Score")]
                    ),
                    _c(
                      "th",
                      {
                        staticClass:
                          "secondary--text subtitle1 text-sm-h6 font-weight-bold"
                      },
                      [_vm._v("Total")]
                    )
                  ])
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.leaders, function(leader, index) {
                    return _c(
                      "tr",
                      {
                        key: index + leader.name,
                        staticClass: "border rounded-lg"
                      },
                      [
                        _c("td", { staticClass: "subtitle1 text-sm-h6" }, [
                          _vm._v(_vm._s(index + 1))
                        ]),
                        _c("td", { staticClass: "subtitle1 text-sm-h6" }, [
                          _vm._v(_vm._s(leader.name))
                        ]),
                        _c(
                          "td",
                          { staticClass: "subtitle1 text-sm-h6" },
                          [
                            _c(
                              "v-chip",
                              {
                                staticClass:
                                  "subtitle1 text-sm-h5 accent2--text font-weight-black",
                                attrs: {
                                  color: "secondary",
                                  label: "",
                                  small: _vm.$vuetify.breakpoint.xs
                                }
                              },
                              [
                                _vm._v(
                                  "+ " +
                                    _vm._s(
                                      leader.details[leader.details.length - 1]
                                        .score
                                    )
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c("td", { staticClass: "subtitle1 text-sm-h6" }, [
                          _vm._v(_vm._s(leader.totalscore))
                        ])
                      ]
                    )
                  }),
                  0
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }