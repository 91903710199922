var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-progress-circular",
    {
      attrs: {
        size: 200,
        rotate: -90,
        color: "secondary",
        width: 20,
        value: _vm.progressValue
      }
    },
    [
      _c(
        "span",
        {
          staticClass:
            "text-h3 animate__animated animate__pulse animate__infinite font-weight-black"
        },
        [_vm._v(_vm._s(_vm.seconds))]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }