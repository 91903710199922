var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    {
      staticClass:
        "d-flex justify-space-between justify-lg-center align-center",
      attrs: {
        cols: _vm.$vuetify.breakpoint.xl
          ? 4
          : _vm.$vuetify.breakpoint.lg
          ? 5
          : 12,
        order: _vm.$vuetify.breakpoint.mdAndUp ? 0 : 1
      }
    },
    [
      _c(
        "v-chip",
        {
          staticClass: "pa-4 mr-2",
          attrs: {
            small: _vm.$vuetify.breakpoint.xs,
            label: "",
            color: _vm.participantSession.color,
            dark: ""
          }
        },
        [
          _c("font-awesome-icon", { attrs: { icon: "user", pull: "left" } }),
          _c("span", { staticClass: "font-weight-bold" }, [
            _vm._v(_vm._s(_vm.participantSession.name))
          ])
        ],
        1
      ),
      _c(
        "v-chip",
        {
          staticClass: "pa-4 mr-2",
          attrs: {
            small: _vm.$vuetify.breakpoint.xs,
            label: "",
            color: "secondary",
            dark: ""
          }
        },
        [
          _c("font-awesome-icon", { attrs: { icon: "star", pull: "left" } }),
          _c("span", { staticClass: "mr-2" }, [_vm._v("Score")]),
          _c("span", { staticClass: "font-weight-bold" }, [
            _vm._v(_vm._s(_vm.participantSession.totalscore))
          ])
        ],
        1
      ),
      _c(
        "v-chip",
        {
          staticClass: "pa-4 mr-2",
          attrs: {
            small: _vm.$vuetify.breakpoint.xs,
            label: "",
            color: "secondary",
            dark: ""
          }
        },
        [
          _c("font-awesome-icon", { attrs: { icon: "bolt", pull: "left" } }),
          _c("span", { staticClass: "mr-2" }, [_vm._v("Powerups")]),
          _c("span", { staticClass: "font-weight-bold" }, [
            _vm._v(_vm._s(_vm.availablePowerUps))
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }