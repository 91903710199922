var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.question
    ? _c(
        "v-row",
        {
          staticClass: "d-flex answer-section justify-center align-self-end",
          attrs: { dense: _vm.$vuetify.breakpoint.xs }
        },
        [
          _c("ParticipantPowerups", {
            attrs: { removables: _vm.removables },
            on: {
              "increment-powerup": _vm.incrementPowerUp,
              "erase-answer": _vm.eraseAnswer
            }
          }),
          _vm._l(_vm.question.answers, function(answer, answerindex) {
            return _c(
              "v-col",
              {
                key: answerindex,
                staticClass: "d-flex justify-center align-stretch ma-0",
                attrs: { cols: "12", sm: "6" }
              },
              [
                _c("v-hover", {
                  staticClass: "title",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var hover = ref.hover
                          return [
                            !_vm.removables.includes(answer)
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "font-weight-bold answercontent flex-grow-1 d-flex justify-start pa-6 pa-sm-8 rounded-lg",
                                    class: [
                                      _vm.selectedAnswerIndex === answerindex
                                        ? "clicked"
                                        : _vm.answercolor[answerindex],
                                      _vm.selectedAnswer ? "disable-events" : ""
                                    ],
                                    attrs: {
                                      "x-large": "",
                                      dark: "",
                                      color: _vm.answercolor[answerindex],
                                      elevation:
                                        (hover &&
                                          _vm.selectedAnswerIndex === null) ||
                                        answerindex === _vm.selectedAnswerIndex
                                          ? 24
                                          : 0
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.setSelectedAnswer(
                                          answer,
                                          answerindex
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "mr-4 font-weight-bold",
                                        class:
                                          _vm.answercolor[answerindex] +
                                          "--text text--lighten-4"
                                      },
                                      [_vm._v(_vm._s(answerindex + 1))]
                                    ),
                                    _vm._v(_vm._s(answer))
                                  ]
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              ],
              1
            )
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }