<template>
  <v-row class="d-flex justify-center align-self-end">
    <v-col cols="auto">
      <v-btn
        large
        outlined
        color="secondary"
        class="rounded-lg"
        v-if="gameSession.type === 'S' && !participantSession.end && !participantSession.showanswer"
        @click="skip"
      >
        <font-awesome-icon icon="forward" pull="left"></font-awesome-icon>
        Skip
      </v-btn>
    </v-col>
    <v-col cols="auto">
      <v-btn
        large
        outlined
        color="secondary"
        class="rounded-lg"
        v-if="gameSession.type === 'S' && !participantSession.end && participantSession.showanswer"
        @click="nextquestion"
      >
        <font-awesome-icon icon="arrow-circle-right" pull="left"></font-awesome-icon>
        Next
      </v-btn>
    </v-col>
    <v-col cols="auto">
      <v-btn large class="rounded-lg" outlined color="secondary" @click="endquiz" v-if="gameSession.type !== 'L'">
        <font-awesome-icon icon="stop" pull="left"></font-awesome-icon>
        End Quiz
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex"
export default {
  name: "ParticipantActionBar",
  props: {
    notimer: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    ...mapState({
      participantSession: (state) => state.game.participantSession,
      gameSession: (state) => state.game.gameSession,
    }),
  },
  methods: {
    skip() {
      this.$eventBus.$emit("send-data-to-firebase")
    },
    nextquestion() {
      // console.log("nextquestion")
      this.$eventBus.$emit("next-question")
    },
    endquiz() {
      this.$eventBus.$emit("ask-confirmation")
    },
  },
}
</script>

<style lang="scss" scoped></style>
