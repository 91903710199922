<template>
  <v-col cols="12" class="d-flex justify-center" v-if="showPowerUps">
    <v-card outlined color="transparent" elevation="0" class="flex-grow-1 d-flex justify-center rounded-lg">
      <ParticipantHintPopover :disabled="allowHint" @hint-used="hintUsageUpdate"></ParticipantHintPopover>
      <v-btn color="secondary" class="text-sm-h6 subtitle1 rounded-lg ml-2" @click="erase" :disabled="allowEraser">
        <font-awesome-icon icon="eraser" pull="left"></font-awesome-icon>
        Eraser
      </v-btn>
    </v-card>
  </v-col>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import { arraySample } from "@/mixins/arraySample.js"
import ParticipantHintPopover from "@/components/game/participant/ParticipantHintPopover"
export default {
  name: "ParticipantPowerups",
  props: {
    removables: {
      default: () => [],
      type: Array,
    },
  },
  components: {
    ParticipantHintPopover,
  },
  mixins: [arraySample],
  data() {
    return {
      hintusage: 0,
      eraserusage: 0,
      image: false,
    }
  },
  computed: {
    ...mapGetters({ question: "getCurrentQuizQuestion" }),
    ...mapState({
      participantSession: (state) => state.game.participantSession,
      gameSession: (state) => state.game.gameSession,
    }),

    allowHint() {
      return !this.gameSession.lifelines.includes("hint") || this.question.answertype === "P" || !this.question.hint
    },

    allowEraser() {
      return (
        !this.gameSession.lifelines.includes("eraser") ||
        this.question.answertypeanswers?.length <= 2 ||
        this.question.answertype !== "M"
      )
    },

    showPowerUps() {
      return !this.gameSession.showanswer &&
        this.gameSession.advanced &&
        this.gameSession.lifelines?.length > 0 &&
        this.participantSession.powerupcount < this.gameSession.maxlifelines
        ? true
        : !this.gameSession.showanswer && this.gameSession.randomlifeline
        ? true
        : false
    },
  },

  methods: {
    answerToRemove() {
      const choices = this.question.answers?.length - this.removables?.length
      const answersremoved = choices <= 2 ? 0 : 1
      if (answersremoved > 0) {
        return this.getRandomArray(
          this.question.answers.filter(
            (answer, index) => !this.question.correctanswers[index] && !this.removables.includes[answer]
          ),
          answersremoved
        )[0]
      } else {
        return []
      }
    },

    hintUsageUpdate() {
      this.hintusage = this.hintusage === 0 ? 1 : 1
      this.$emit("increment-powerup", this.hintusage)
    },

    erase() {
      const removables = this.answerToRemove()
      if (removables) {
        this.$emit("increment-powerup", 1)
        this.$emit("erase-answer", removables)
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
