<template>
  <v-progress-circular :size="200" :rotate="-90" color="secondary" :width="20" :value="progressValue"
    ><span class="text-h3 animate__animated animate__pulse animate__infinite font-weight-black">{{
      seconds
    }}</span></v-progress-circular
  >
</template>

<script>
import { mapGetters } from "vuex"
export default {
  name: "ParticipantWaitTimer",
  props: {
    seconds: {
      default: () => 0,
      type: Number,
    },
    standardtime: {
      default: () => 0,
      type: Number,
    },
  },
  computed: {
    ...mapGetters({ question: "getCurrentQuizQuestion" }),
    progressValue() {
      return (this.seconds / this.standardtime) * 100
    },
  },
}
</script>

<style lang="scss" scoped></style>
