var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c(
        "v-container",
        { staticClass: "wrapper ma-0 pa-0", attrs: { fluid: "" } },
        [
          _vm.gameSession
            ? [
                !_vm.viewquestion && _vm.participantSession.name
                  ? _c("ParticipantWaitCard", {
                      attrs: { username: _vm.participantSession.name },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "timer",
                            fn: function() {
                              return [
                                _c("ParticipantWaitTimer", {
                                  attrs: {
                                    seconds: _vm.gaptime,
                                    standardtime: _vm.standardtime
                                  }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        1455538086
                      )
                    })
                  : _vm._e(),
                _vm.viewquestion && !_vm.gameEnded
                  ? _c("ParticipantQuestion", {
                      attrs: {
                        gaptime: _vm.gaptime,
                        time: _vm.time,
                        route: _vm.route
                      }
                    })
                  : _vm._e()
              ]
            : _vm._e(),
          _c("ConfirmDialog", { ref: "confirm" })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }