<template>
  <v-container fluid class="leaderboard justify-start">
    <lottie-player
      class="lottie"
      src="https://assets1.lottiefiles.com/packages/lf20_m2emnrvx.json"
      background="transparent"
      speed="1"
      style="width: 100%; height: 100%"
      autoplay
    ></lottie-player>

    <v-simple-table class="transparent">
      <template v-slot:default>
        <thead>
          <tr class="rounded-lg">
            <th class="secondary--text subtitle1 text-sm-h6 font-weight-bold">Rank</th>
            <th class="secondary--text subtitle1 text-sm-h6 font-weight-bold">Name</th>
            <th class="secondary--text subtitle1 text-sm-h6 font-weight-bold">Score</th>
            <th class="secondary--text subtitle1 text-sm-h6 font-weight-bold">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(leader, index) in leaders" :key="index + leader.name" class="border rounded-lg">
            <td class="subtitle1 text-sm-h6">{{ index + 1 }}</td>
            <td class="subtitle1 text-sm-h6">{{ leader.name }}</td>
            <td class="subtitle1 text-sm-h6">
              <v-chip
                color="secondary"
                class="subtitle1 text-sm-h5 accent2--text font-weight-black"
                label
                :small="$vuetify.breakpoint.xs"
                >+ {{ leader.details[leader.details.length - 1].score }}</v-chip
              >
            </td>
            <td class="subtitle1 text-sm-h6">{{ leader.totalscore }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  name: "ParticipantLeaderBoard",
  computed: {
    ...mapGetters({ leaders: "getLeaders" }),
  },
}
</script>

<style lang="scss" scoped>
.leaderboard {
  position: relative;
}
.lottie {
  position: absolute;
  top: 0;
  left: 0;
}
.border {
  border-bottom: 1px solid var(--v-accent2-base);
}
</style>
