<template>
  <v-container
    class="background question-card d-flex justify-start align-start ma-0 pa-2 pa-sm-4 pa-xl-8 mx-auto"
    :fluid="$vuetify.breakpoint.xs"
    fill-height
  >
    <ParticipantTimer :seconds="time || gaptime">
      <template v-slot:participant-info>
        <ParticipantInfo></ParticipantInfo>
      </template>
    </ParticipantTimer>
    <GameQuestion v-if="!gameSession.showleader">
      <template v-slot:game-question-image v-if="!viewanswer">
        <GameQuestionImage :image="question.imagelink"> </GameQuestionImage>
      </template>
    </GameQuestion>

    <ParticipantAnswerList :time="time" v-if="showanswerlist" :route="route"> </ParticipantAnswerList>
    <ParticipantRightAnswer v-if="!showanswerlist">
      <template v-slot:leaderboard v-if="gameSession.type !== 'L'">
        <ParticipantLeaderBoard></ParticipantLeaderBoard>
      </template>
      <template v-slot:answer-stats v-else>
        <GameAnswerStats></GameAnswerStats>
      </template>
    </ParticipantRightAnswer>

    <ParticipantActionBar v-if="gameSession.type !== 'L'"></ParticipantActionBar>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import ParticipantTimer from "@/components/game/participant/ParticipantTimer"
import GameQuestion from "@/components/game/common/GameQuestion"
import GameQuestionImage from "@/components/game/common/GameQuestionImage"
import ParticipantInfo from "@/components/game/participant/ParticipantInfo"
import ParticipantAnswerList from "@/components/game/participant/ParticipantAnswerList"
import ParticipantActionBar from "@/components/game/participant/ParticipantActionBar"
import ParticipantRightAnswer from "@/components/game/participant/ParticipantRightAnswer"
import ParticipantLeaderBoard from "@/components/game/participant/ParticipantLeaderBoard"
import GameAnswerStats from "@/components/game/common/GameAnswerStats"

export default {
  name: "ParticipantQuestion",
  props: {
    gaptime: {
      type: Number,
      default: () => 0,
    },
    time: {
      type: Number,
      default: () => 0,
    },
    route: {
      type: String,
      default: () => "",
    },
  },
  components: {
    ParticipantTimer,
    GameQuestion,
    GameQuestionImage,
    ParticipantInfo,
    ParticipantAnswerList,
    ParticipantActionBar,
    ParticipantRightAnswer,
    ParticipantLeaderBoard,
    GameAnswerStats,
  },
  computed: {
    ...mapGetters({ question: "getCurrentQuizQuestion" }),
    ...mapState({
      participantSession: (state) => state.game.participantSession,
      gameSession: (state) => state.game.gameSession,
    }),

    viewanswer() {
      return this.gameSession?.type === "L"
        ? this.gameSession.showanswer && !this.gameSession.showleader
        : this.participantSession.showanswer
    },

    showanswerlist() {
      return this.gameSession.type === "L"
        ? !this.gameSession.showanswer && !this.gameSession.showleader
        : !this.participantSession.showanswer
    },
  },
}
</script>

<style lang="scss" scoped>
.question-card {
  min-height: 100vh;
  // background-image: url("~@/assets/background.svg");
  // background-position: left top;
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-blend-mode: overlay;

  background: rgb(255, 243, 224);
  background: linear-gradient(180deg, rgba(255, 243, 224, 1) 50%, rgba(255, 223, 173, 1) 75%);
  //   min-height: 90vh;
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-position: left top;
  // background-image: url("~@/assets/background.svg"),
  //   linear-gradient(90deg, rgba(255, 243, 224, 1) 20%, rgba(255, 223, 173, 1) 28%);
  // background-blend-mode: overlay;
}
</style>
