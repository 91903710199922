var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.question
    ? _c(
        "v-row",
        {
          staticClass:
            "d-flex timer-section justify-sm-space-between justify-center align-self-start pa-2",
          attrs: { dense: "" }
        },
        [
          _vm._t("participant-info"),
          _c(
            "v-col",
            {
              staticClass: "d-flex justify-center align-center",
              attrs: {
                cols: _vm.$vuetify.breakpoint.xl
                  ? 8
                  : _vm.$vuetify.breakpoint.lg
                  ? 7
                  : 12,
                order: _vm.$vuetify.breakpoint.mdAndUp ? 0 : 1
              }
            },
            [
              _c(
                "v-progress-linear",
                {
                  staticClass: "rounded-lg",
                  attrs: {
                    dark: "",
                    elevation: "0",
                    color: "accent2",
                    "background-color": "background",
                    height: _vm.$vuetify.breakpoint.smAndUp ? 40 : 20,
                    value: _vm.progressValue
                  }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "font-weight-bold secondary--text timer-text"
                    },
                    [_vm._v(_vm._s(_vm.seconds))]
                  )
                ]
              )
            ],
            1
          )
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }