var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass:
        "background question-card d-flex justify-start align-start ma-0 pa-2 pa-sm-4 pa-xl-8 mx-auto",
      attrs: { fluid: _vm.$vuetify.breakpoint.xs, "fill-height": "" }
    },
    [
      _c("ParticipantTimer", {
        attrs: { seconds: _vm.time || _vm.gaptime },
        scopedSlots: _vm._u([
          {
            key: "participant-info",
            fn: function() {
              return [_c("ParticipantInfo")]
            },
            proxy: true
          }
        ])
      }),
      !_vm.gameSession.showleader
        ? _c("GameQuestion", {
            scopedSlots: _vm._u(
              [
                !_vm.viewanswer
                  ? {
                      key: "game-question-image",
                      fn: function() {
                        return [
                          _c("GameQuestionImage", {
                            attrs: { image: _vm.question.imagelink }
                          })
                        ]
                      },
                      proxy: true
                    }
                  : null
              ],
              null,
              true
            )
          })
        : _vm._e(),
      _vm.showanswerlist
        ? _c("ParticipantAnswerList", {
            attrs: { time: _vm.time, route: _vm.route }
          })
        : _vm._e(),
      !_vm.showanswerlist
        ? _c("ParticipantRightAnswer", {
            scopedSlots: _vm._u(
              [
                _vm.gameSession.type !== "L"
                  ? {
                      key: "leaderboard",
                      fn: function() {
                        return [_c("ParticipantLeaderBoard")]
                      },
                      proxy: true
                    }
                  : {
                      key: "answer-stats",
                      fn: function() {
                        return [_c("GameAnswerStats")]
                      },
                      proxy: true
                    }
              ],
              null,
              true
            )
          })
        : _vm._e(),
      _vm.gameSession.type !== "L" ? _c("ParticipantActionBar") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }