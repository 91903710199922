var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showPowerUps
    ? _c(
        "v-col",
        { staticClass: "d-flex justify-center", attrs: { cols: "12" } },
        [
          _c(
            "v-card",
            {
              staticClass: "flex-grow-1 d-flex justify-center rounded-lg",
              attrs: { outlined: "", color: "transparent", elevation: "0" }
            },
            [
              _c("ParticipantHintPopover", {
                attrs: { disabled: _vm.allowHint },
                on: { "hint-used": _vm.hintUsageUpdate }
              }),
              _c(
                "v-btn",
                {
                  staticClass: "text-sm-h6 subtitle1 rounded-lg ml-2",
                  attrs: { color: "secondary", disabled: _vm.allowEraser },
                  on: { click: _vm.erase }
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "eraser", pull: "left" }
                  }),
                  _vm._v(" Eraser ")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }