<template>
  <v-row class="d-flex answer-section justify-center align-self-end" :dense="$vuetify.breakpoint.xs" v-if="question">
    <ParticipantPowerups
      @increment-powerup="incrementPowerUp"
      @erase-answer="eraseAnswer"
      :removables="removables"
    ></ParticipantPowerups>
    <v-col
      cols="12"
      sm="6"
      v-for="(answer, answerindex) in question.answers"
      :key="answerindex"
      class="d-flex justify-center align-stretch ma-0"
    >
      <v-hover v-slot="{ hover }" class="title">
        <v-btn
          x-large
          dark
          :class="[
            selectedAnswerIndex === answerindex ? 'clicked' : answercolor[answerindex],
            selectedAnswer ? 'disable-events' : '',
          ]"
          :color="answercolor[answerindex]"
          @click="setSelectedAnswer(answer, answerindex)"
          v-if="!removables.includes(answer)"
          class="font-weight-bold answercontent flex-grow-1 d-flex justify-start pa-6 pa-sm-8 rounded-lg"
          :elevation="(hover && selectedAnswerIndex === null) || answerindex === selectedAnswerIndex ? 24 : 0"
          ><span class="mr-4 font-weight-bold" :class="`${answercolor[answerindex]}--text text--lighten-4`">{{
            answerindex + 1
          }}</span
          >{{ answer }}</v-btn
        >
      </v-hover>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import ParticipantPowerups from "@/components/game/participant/ParticipantPowerups"
import { updateParticipantSession } from "@/api/GameApi"
export default {
  name: "ParticipantAnswerList",
  props: {
    time: {
      type: Number,
      default: () => 0,
    },
    route: {
      type: String,
      default: () => "",
    },
  },
  components: {
    ParticipantPowerups,
  },
  data() {
    return {
      light_mode_logo: require("@/assets/common/logo_lightmode.svg"),
      //
      clicked: false,
      doublescore: 1,
      selectedAnswer: null,
      btncolor: "primarydark",
      selectedAnswerIndex: null,
      powerupused: 0,
      removables: [],
      answercolor: ["success", "error", "info", "warning", "secondary", "accent"],
    }
  },
  mounted() {
    this.$eventBus.$on("send-data-to-firebase", this.setSelectedAnswer)
    this.initAnswers()
  },
  destroy() {
    this.$eventBus.$off("send-data-to-firebase")
  },
  watch: {
    time: function (newval, oldval) {
      if (newval === 0 && oldval > 0 && !this.clicked) {
        // console.log("gameanswertimewatcher")
        this.sendDataToFirebase()
      }
    },
  },
  computed: {
    ...mapGetters({ question: "getCurrentQuizQuestion" }),
    ...mapState({
      participantSession: (state) => state.game.participantSession,
      gameSession: (state) => state.game.gameSession,
    }),

    showanswervalue() {
      return this.gameSession?.type !== "L" ? true : false
    },
    isAnswerRight() {
      return this.question.correctanswers[this.selectedAnswerIndex]
    },
    elapsedTime() {
      return this.notimer ? 5 : this.question.timer - this.time
    },
    streak() {
      if (this.participantSession.lastselected.streak >= 0 && this.isAnswerRight) {
        return this.participantSession.lastselected.streak + 1
      } else {
        return 0
      }
    },
    answer: {
      get() {
        return this.selectedAnswer
          ? { answer: this.selectedAnswer, isRight: this.isAnswerRight }
          : { answer: "No Answer", isRight: false }
      },
      set(value) {
        return value
      },
    },
    answerDataForUpload: {
      get() {
        return {
          ...this.question,
          score: this.score,
          selectedanswer: this.selectedAnswerIndex || "",
          chosenanswer: this.answer.answer || "No Answer",
          elapsedtime: this.elapsedTime,
          powerupcount: this.powerupcount,
        }
      },
      set(value) {
        return value
      },
    },
    lastSelectedForUpload: {
      get() {
        return {
          ...this.answer,
          elapsedtime: this.elapsedTime,
          streak: this.streak,
        }
      },
      set(value) {
        return value
      },
    },
    powerupcount() {
      return this.participantSession.powerupcount + this.powerupused
    },

    score() {
      var tempscore = 0
      if (this.isAnswerRight && this.gameSession.type !== "S") {
        tempscore = this.question.points * (this.question.timer - this.elapsedTime) * this.doublescore
      } else if (this.isAnswerRight && this.gameSession.type === "S") {
        tempscore = this.question.points * this.doublescore
      }
      if (this.gameSession.reducescore && this.powerupused) {
        tempscore = tempscore - Math.ceil((tempscore * this.gameSession.reducepercent) / 100 / this.powerupused)
      }
      return tempscore
    },

    totalscore() {
      return this.participantSession.totalscore + this.score
    },
  },
  methods: {
    initAnswers() {
      this.selectedAnswer = null
      this.removables = []
      this.answer = null
      this.answerDataForUpload = null
      this.lastSelectedForUpload = null
      this.clicked = false
      this.selectedAnswerIndex = null
      this.doublescore = 1
    },

    incrementPowerUp(val) {
      this.powerupused += val
    },

    eraseAnswer(val) {
      this.removables.push(val)
    },

    setSelectedAnswer(selectedAnswer, selectedAnswerIndex) {
      this.selectedAnswer = selectedAnswer
      this.selectedAnswerIndex = selectedAnswerIndex
      this.sendDataToFirebase()
      this.clicked = true
    },

    sendDataToFirebase() {
      console.log("Called")
      updateParticipantSession(
        this.route,
        {
          totalscore: this.totalscore,
          lastselected: this.lastSelectedForUpload,
          powerupcount: this.powerupcount,
          showanswer: this.showanswervalue, //relevant only for non-interactive
        },
        this.answerDataForUpload
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.answer-section {
  .answercontent {
    font-size: clamp(1rem, 2.3vmax, 3rem) !important;
    word-break: normal;
    &:hover {
      border-bottom: 0.8rem solid black !important;
    }
  }
  .disable-events {
    pointer-events: none;
  }
  .success {
    border-bottom: 0.8rem solid var(--v-success-darken2) !important;
  }
  .error {
    border-bottom: 0.8rem solid var(--v-error-darken2) !important;
  }
  .info {
    border-bottom: 0.8rem solid var(--v-info-darken2) !important;
  }
  .warning {
    border-bottom: 0.8rem solid var(--v-warning-darken2) !important;
  }
  .secondary {
    border-bottom: 0.8rem solid var(--v-secondary-darken2) !important;
  }
  .accent {
    border-bottom: 0.8rem solid var(--v-accent-darken2) !important;
  }
  .clicked {
    border-bottom: 0.8rem solid black !important;
  }
}
</style>
