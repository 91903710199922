import { render, staticRenderFns } from "./ParticipantInfo.vue?vue&type=template&id=fff02a10&scoped=true&"
import script from "./ParticipantInfo.vue?vue&type=script&lang=js&"
export * from "./ParticipantInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fff02a10",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
installComponents(component, {VChip,VCol})


/* hot reload */
if (module.hot) {
  var api = require("/Users/asif/dev/js/qizto/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fff02a10')) {
      api.createRecord('fff02a10', component.options)
    } else {
      api.reload('fff02a10', component.options)
    }
    module.hot.accept("./ParticipantInfo.vue?vue&type=template&id=fff02a10&scoped=true&", function () {
      api.rerender('fff02a10', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/game/participant/ParticipantInfo.vue"
export default component.exports