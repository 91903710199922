<template>
  <v-container class="background waitcard" fill-height v-if="gameSession">
    <v-row justify="center" align="center" no-gutters>
      <v-col cols="auto" v-if="gameSession.type === 'L'">
        <lottie-player
          src="https://assets10.lottiefiles.com/packages/lf20_2LBdNH.json"
          background="transparent"
          speed="1"
          style="width: 50vw; height: 50vh"
          loop
          autoplay
        ></lottie-player>
      </v-col>

      <v-col cols="12">
        <p class="text-h4 text-center secondary--text font-weight-bold">welcome {{ username }}</p>
        <p class="text-h5 text-center secondary--text font-weight-medium" v-if="gameSession.type === 'L'">
          please wait. while others are joining!
        </p>
        <p class="text-h5 text-center secondary--text font-weight-medium" v-if="gameSession.type === 'C'">
          Brace yourself! Next Question In....
        </p>
        <p class="text-h5 text-center secondary--text font-weight-medium" v-if="gameSession.type === 'S'">
          Getting things ready...We start in..
        </p>
      </v-col>
      <v-col
        col="12"
        v-if="gameSession.type === 'C' || gameSession.type === 'S'"
        class="pt-6 d-flex justify-center align-center"
      >
        <slot name="timer"></slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "ParticipantWaitCard",
  props: {
    username: {
      type: String,
      default: () => "",
    },
  },
  computed: {
    ...mapState({
      gameSession: (state) => state.game.gameSession,
    }),
  },
}
</script>

<style lang="scss" scoped>
.waitcard {
  min-height: 100vh;
}
</style>
